import {get} from "lodash-es";

// DateRangeInput returns value in format YYYY-MM-DD, needed Filter format is YYYY-MM-DDTHH:mm:ss.SZ

export default {
  data() {
    return {
      today: new Date(),
    }
  },
  methods: {
    getFilterDateRange(value) {
      const from = get(value, 'startDate');
      const to = get(value, 'endDate');

      if (!from || !to) {
        return {
          from: undefined,
          to: undefined,
        };
      }

      return {
        from: `${from}T00:00:00.0Z`,
        to: `${to}T23:59:59.0Z`,
      }
    }
  }
};
