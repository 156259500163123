<template>
   <div class="h-full flex flex-col">
      <loader :loading="loading" :backdrop="true" />
      <List
      resource="getAllRenewals"
      :fields="fields"
      ref="list_leases"
      :pageSize="18"
      trackBy="unit"
      :search="true"
      :clickable="true"
      :isCommunityRequired="true"
      :initial-filter="filter"
      :filter-mapper="mapFilterToQuery"
      @rowData="getRenewal"
      >

       <template v-slot:filters="props">
        <div class="form-row">
            <div class="form-col">
               <SelectInput
                  name="agentIds"
                  label="agent"
                  :options="agents"
                  multiple
               />
            </div>
            <div class="form-col">
               <SelectInput
                  name="statusIds"
                  label="status"
                  :options="statuses"
                  multiple
               />
            </div>
        </div>
        <div class="form-row justify-end">
          <button @click.stop="props.reset()" class="btn-transparent mr-8">Reset</button>
          <button class="btn-primary">Filter</button>
        </div>
      </template>
      </List>
   </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import DateRangeFilterMixin from '@/components/auth/DateRangeFilterMixin';
import ModalNavigation from '@/mixins/ModalNavigation'
import Loader from '@/components/ui/Loader'
import List from '@/components/move_in/List'
import SelectInput from "@/components/form/SelectInput";

// import Icon from "@/components/ui/Icon";

export default {
   mixins: [NotifyMixin, AuthMixin, ModalNavigation,DateRangeFilterMixin],
   components: {
      Loader,
      List,
    //   Icon
      SelectInput
   },
   data() {
      return {
         loading: false,
         filter: {
            // moveInDate: {
            //    startDate: null,
            //    endDate: null,
            // }
            agentIds: null,
            statusIds: null
         },

         agents:[{
            key: 0,
            value: 'agent 1'
         },
         {
            key: 1,
            value: 'agent 2'
         }],
         statuses: [{
            key: 0,
            value: 'Not Started'
         },
         {
            key: 1,
            value: 'Offer Creation'
         },
            {
            key: 3,
            value: 'Offer Sent'
         },
            {
            key: 4,
            value: 'Resident Discussions'
         },
            {
            key: 5,
            value: 'Offer Accepted'
         },
            {
            key: 6,
            value: 'Renewal In Progress'
         },
            {
            key: 7,
            value: 'Waiting Resident Signature'
         },
            {
            key: 8,
            value: 'Waiting Office Signature'
         },
            {
            key: 9,
            value: 'Complete'
         },
            {
            key: 10,
            value: 'NTV Given'
         },
         ],

         fields: [
            {
               name: 'unit',
               title: 'unit',
               sortField: 'unit'
            },
            {
               name: 'move_in_date',
               title: 'move in date',
               sortField: 'move_in_date'
            },
            {
               name: 'lease_end_date',
               title: 'lease end date',
               sortField: 'lease_end_date'
            },
            {
               name: 'residents',
               title: 'residents',
               sortField: 'residents',
               formatter: (values) => {
                  let data = '';

                  values.slice(0,3).forEach((resident) => {
                     data += `<span class="border-gray-400 border-2 p-1 mr-3 text-xs	">${resident.name}</span>` ;
                  });

                  if(values.length > 3) data +=`+ ${values.length-3}`

                  return data
               },
            },
            {
               name: 'agent',
               title: 'agent',
               sortField: 'agent'
            },
            {
               name: 'status',
               title: 'status',
               sortField: 'status'
            },
            {
               name: 'comments',
               title: 'comments',
               sortField: 'comments'
            },

         ],
      }
   },
   mounted() {
    // if (!this.community) this.notifyError('please select a community to continue, then refresh the browser');
  },
   methods:{
       mapFilterToQuery(values) {
         // const { from: lastMoveInFrom, to: lastMoveInTo } = this.getFilterDateRange(values.moveInDate);
         const statusIds =  values.statusIds
         const agentIds =  values.agentIds
         return {
           statusIds,
           agentIds
         }
      },
      getRenewal(){
         this.$router.push({name: 'renewals.editRenewalOffer'});
      }
   },
}
</script>
